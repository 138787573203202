import {
  AudioT,
  AudioDispatchTypes,
} from "../constants/ActionTypes/audio-action-types";

export interface initialAudioState {
  audio: AudioT[];
  deletedAudios: AudioT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  addStatus: boolean;
  total: number;
  search: any;
}

const defaultState: initialAudioState = {
  audio: [],
  deletedAudios: [],
  status: false,
  message: "",
  saveStatus: false,
  addStatus: false,
  total: 0,
  search: {
    name: "",
    originalName: "",
    book: "",
    titleOrder: "",
  }
};

const audioReducer = (
  state: initialAudioState = defaultState,
  action: AudioDispatchTypes
): initialAudioState => {
  switch (action.type) {
    case "AUDIOS_LOADING":
      return Object.assign({}, state, {
        audio: [],
        status: false,
        message: "",
      });
    case "AUDIOS_LOADED":
      return Object.assign({}, state, {
        audio: action.payload.audios,
        total: action.payload.count,
        status: true,
        message: "",
      });
    case "AUDIOS_ERROR":
      return Object.assign({}, state, {
        audio: [],
        status: false,
        message: action.payload,
      });
    case "AUDIO_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "AUDIO_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "AUDIO_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case "AUDIO_ADD_START":
      return Object.assign({}, state, {
        addStatus: false,
      });
    case "AUDIO_ADD_SUCCESS":
      return Object.assign({}, state, {
        addStatus: true,
      });
    case "AUDIO_ADD_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        addStatus: action.payload,
      });
    case "DELETED_AUDIOS_LOADING":
      return Object.assign({}, state, {
        deletedAudios: [],
        status: false,
        message: "",
      });
    case "DELETED_AUDIOS_LOADED":
      return Object.assign({}, state, {
        deletedAudios: action.payload.audios,
        status: true,
        message: "",
      });
    case "DELETED_AUDIOS_ERROR":
      return Object.assign({}, state, {
        deletedAudios: [],
        status: false,
        message: action.payload,
      });
    case "AUDIO_CHANGE_SEARCH":
      const s = state.search;
      (s as any)[action.payload.field] = action.payload.value;
      const n = {
        search: s
      };
      return Object.assign({}, state, n);
    default:
      return state;
  }
};

export default audioReducer;
