import { Button, Input, Popconfirm, Space, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store/store";
import {
  DownloadOutlined,
  RedoOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import WebContentActions from "../../actions/webcontent";
import { WebContentT } from "../../constants/ActionTypes/webcontent-action-types";
import "../formating/content.css";
import useWindowDimensions from "../../constants/sizecheck";
import { downloadContent } from "./webcontent";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";

export default function DeletedWebcontent({ showDeletedModal }: any) {
  const dispatch = useDispatch();
  const propDeletedWebContents = useSelector(
    (state: RootStore) => state.webcontentReducer.deletedWebcontent
  );
  const propStatus = useSelector(
    (state: RootStore) => state.webcontentReducer.status
  );
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const { height } = useWindowDimensions();
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);
  useEffect(() => {
    const fetch = async () => {
      if (showDeletedModal) {
        try {
          await dispatch(WebContentActions.getAllDeletedWebContent());
          setLoaded(true);
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetch();
  }, [dispatch, showDeletedModal]);

  const getDeletedWebContents = async () => {
    setLoaded(false);
    try {
      await dispatch(WebContentActions.getAllDeletedWebContent());
      if (propStatus) {
        setLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const restoreDeletedTask = async (record: WebContentT) => {
    await dispatch(WebContentActions.restoreWebContent(record.id.toString()));
    await getDeletedWebContents();
  };

  type DataIndex = keyof WebContentT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<WebContentT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Web content megnevezés",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "megnevezést"),
      sorter: (a: WebContentT, b: WebContentT) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Eredeti név",
      key: "originalName",
      dataIndex: "originalName",
      ...getColumnSearchProps("originalName", "eredeti nevet"),
    },
    {
      title: "Letöltés",
      key: "download",
      render: (text: string, record: WebContentT) => {
        if (record.url.includes(".pdf")) {
          return (
            <Space size="middle">
              <Button onClick={() => downloadContent(record)}>
                <DownloadOutlined />
              </Button>
            </Space>
          );
        } else {
          return [];
        }
      },
    },
    {
      title: "Visszaállítás",
      key: "restore",
      render: (text: string, record: WebContentT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan vissza akarod állítani ezt a kontentet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => restoreDeletedTask(record)}
            >
              <Button type="primary">
                <RedoOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <h1 className="title">
        <strong>Törölt webkontentek</strong>
      </h1>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propDeletedWebContents}
        loading={!loaded && !propDeletedWebContents}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
        scroll={{ y: height / 2 }}
      />
    </div>
  );
}
