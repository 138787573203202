import {
  VideoT,
  VideoDispatchTypes,
} from "../constants/ActionTypes/video-action-types";

export interface initialVideoState {
  video: VideoT[];
  deletedVideos: VideoT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  addSaveStatus: boolean;
  total: number;
  search: any;
}

const defaultState = {
  video: [],
  deletedVideos: [],
  status: false,
  message: "",
  saveStatus: false,
  addSaveStatus: false,
  total: 0,
  search: {
    name: "",
    originalName: "",
    book: "",
    titleOrder: "",
  }
};
const videoReducer = (
  state: initialVideoState = defaultState,
  action: VideoDispatchTypes
): initialVideoState => {
  switch (action.type) {
    case "VIDEOS_LOADING":
      return Object.assign({}, state, {
        video: [],
        status: false,
        message: "",
      });
    case "VIDEOS_LOADED":
      return Object.assign({}, state, {
        video: action.payload.videos,
        total: action.payload.count,
        status: true,
        message: "",
      });
    case "VIDEOS_ERROR":
      return Object.assign({}, state, {
        video: [],
        status: false,
        message: action.payload,
      });
    case "VIDEO_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "VIDEO_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
        message: action.payload,
      });
    case "VIDEO_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case "VIDEO_ADD_REGISTER_START":
      return Object.assign({}, state, {
        addSaveStatus: false,
      });
    case "VIDEO_ADD_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        addSaveStatus: true,
      });
    case "VIDEO_ADD_REGISTER_ERROR":
      return Object.assign({}, state, {
        addSaveStatus: false,
        message: action.payload,
      });
    case "DELETED_VIDEOS_LOADING":
      return Object.assign({}, state, {
        deletedVideos: [],
        status: false,
        message: "",
      });
    case "DELETED_VIDEOS_LOADED":
      return Object.assign({}, state, {
        deletedVideos: action.payload.videos,
        status: true,
        message: "",
      });
    case "DELETED_VIDEOS_ERROR":
      return Object.assign({}, state, {
        deletedVideos: [],
        status: false,
        message: action.payload,
      });
    case "VIDEO_CHANGE_SEARCH":
      const s = state.search;
      (s as any)[action.payload.field] = action.payload.value;
      const n = {
        search: s
      };
      return Object.assign({}, state, n);
    default:
      return state;
  }
};

export default videoReducer;
