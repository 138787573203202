import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Modal,
  Input,
  message,
  Upload,
  Select,
  Space,
  Table,
  Transfer, TablePaginationConfig,
} from "antd";
import VideoActions from "../../../actions/video";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  RestOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import pic from "../../../static/149125.png";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { RootStore } from "../../../store/store";
import { VideoT } from "../../../constants/ActionTypes/video-action-types";
import BookActions from "../../../actions/book";
import { BookT } from "../../../constants/ActionTypes/book-action-types";
import { layout } from "../../../constants/layout";
import DebounceFunc from "../../../constants/debounce";
import "../../formating/content.css";
import DeletedVideos from "./deletedVideos";
import {
  handleChange,
  removePictureFile,
  UploadButton,
} from "../../../constants/pictureUpload";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";

export const downloadContent = async (record: VideoT) => {
  message.info("A letöltés folyamatban, kérem várjon!", 3);
  fetch(
    `${process.env.REACT_APP_BASE_URL}/api/1.0.0/video/download/${record.filename}`,
    {
      method: "GET",
      headers: { "Content-Type": "video/mp4" },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const newUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = newUrl;
      link.setAttribute(
        "download",
        record.originalName ? record.originalName : "file.mp4"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
      message.error("Hiba a letöltés közben!");
    });
};

export default function Video() {
  const dispatch = useDispatch();
  const effectRan = useRef(false);
  const propVideos = useSelector(
    (state: RootStore) => state.videoReducer.video
  );
  const status = useSelector((state: RootStore) => state.videoReducer.status);
  const saveStatus = useSelector(
    (state: RootStore) => state.videoReducer.saveStatus
  );
  const search = useSelector((state: RootStore) => state.videoReducer.search);
  const propBooks = useSelector((state: RootStore) => state.bookReducer.book);
  const [form] = Form.useForm();
  const [file, setFile] = useState<UploadFile>(() => null!);
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [name, setName] = useState<string>(() => "");
  const [modify, setModify] = useState<boolean>(() => false);
  const [selectedId, setSelectedId] = useState<number>(() => null!);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [checked, setChecked] = useState<boolean>(() => false);
  const [loading, setLoading] = useState<boolean>(() => false);
  const [imageUrl, setImageUrl] = useState<string>(() => "");
  const [bookId, setBookId] = useState<number>(() => null!);
  const [filteredVideos, setFilteredVideos] = useState<VideoT[]>(() => []);
  const [onSearch, setOnSerach] = useState<boolean>(() => false);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(
    () => false
  );
  const [booksTargetKeys, setBooksTargetKeys] = useState<string[]>(() => []);
  const [booksSelectedKeys, setBooksSelectedKeys] = useState<string[]>(
    () => []
  );
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false) {
        await dispatch(VideoActions.getAllVideo());
      }
    };
    fetchData();
    if (status) {
      setLoaded(true);
    }
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, status]);

  const refetch = async () => {
    setLoaded(false);
    try {
      await dispatch(VideoActions.getAllVideo());
      await dispatch(BookActions.getAllBook(true));
      if (status) {
        if (propVideos.length > 0) {
          setLoaded(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFile = () => {
    setFile(null!);
    setImageUrl(null!);
  };

  const onReset = () => {
    form.resetFields();
  };

  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    setSelectedId(null!);
    setName("");
    removeFile();
    setBookId(null!);
    setBooksTargetKeys([]);
    onReset();
  };

  const createVideo = async () => {
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
      formData.append("name", name);
      formData.append("bookId", bookId.toString());
    }
    await dispatch(VideoActions.uploadVideo(formData));
    setCurrent(1);
    setChecked(true);
  };

  const functionModifyVideoName = async () => {
    const toSend = {
      id: selectedId,
      name: name,
      bookIds: booksTargetKeys,
    };
    await dispatch(VideoActions.modifyVideoName(toSend));
    setChecked(true);
  };

  useEffect(() => {
    if (saveStatus && checked) {
      message.success("Sikeres mentés");
    } else if (!saveStatus && checked) {
      message.error("Hiba a video mentése közben", 5);
    }
    return () => {
      if (saveStatus && checked) {
        refetch();
        closeModal();
      }
      setChecked(false);
    };
  }, [checked]);

  const functionDeleteVideo = async (record: VideoT) => {
    await dispatch(VideoActions.deleteVideo(record.id));
    await refetch();
  };

  const showModifyModal = (record: VideoT) => {
    form.setFieldsValue({
      name: record?.name,
      book: record.book,
    });
    setModalVisible(true);
    setModify(true);
    setSelectedId(record.id);
    setName(record.name);
    setBookId(record.book[record.book.length - 1].id);
    setBooksTargetKeys(record.book?.map((b: BookT) => b.id.toString()));
  };

  const beforeUpload = (file: RcFile) => {
    if (file.size / 1024 / 1024 > 100) {
      setFile(null!);
      setImageUrl("");
      message.error("Túl nagy file (max 100MB)");
    }
    return false;
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(info, setLoading, setFile, setImageUrl);
  };

  const searchVideos = () => {
    setCurrent(1);
    dispatch(VideoActions.getAllVideo());
  };

  const resetFilter = () => {
    setOnSerach(false);
    dispatch({ type: "VIDEO_CHANGE_SEARCH", payload: { field: `book`, value: "" } });
  };

  const closeDeletedVideosModal = async () => {
    setShowDeletedModal(false);
    await refetch();
  };

  type DataIndex = keyof VideoT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
    setCurrent(1);
    dispatch(VideoActions.getAllVideo());
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<VideoT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={search[dataIndex]}
          onChange={(e) =>
              dispatch({ type: "VIDEO_CHANGE_SEARCH", payload: { field: dataIndex, value: e.target.value } })
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              dispatch({ type: "VIDEO_CHANGE_SEARCH", payload: { field: dataIndex, value: "" } })
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  useEffect(() => {
    if (!modify ? !file || !name || !bookId : !name || !booksTargetKeys) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [file, name, bookId]);

  const columns = [
    {
      title: "Videó neve",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "nevet"),
      sorter: (a: VideoT, b: VideoT) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Eredeti név",
      key: "originalName",
      dataIndex: "originalName",
      ...getColumnSearchProps("originalName", "eredeti nevet"),
    },
    // NINCS VÉGLEGESÍTVE HOGY KELL E NEKIK
    // {
    //   title: "Videó mérete",
    //   key: "fileSize",
    //   render: (text: string, record: VideoT) => {
    //     return `${record.fileSize} KB`;
    //   },
    // },
    {
      title: "Könyv",
      key: "book",
      render: (text: string, record: VideoT) => {
        const arr = record.book
          ?.map((b) => {
            if (!b.deleted) return b.title + ", ";
          })
          .filter((e) => e !== undefined);

        if (arr.length > 4) {
          return arr.slice(0, 4) + "...";
        } else {
          return arr;
        }
      },
    },
    {
      title: "Létrehozva",
      key: "created",
      render: (text: string, record: VideoT) => {
        return new Date(record.created).toLocaleDateString();
      },
    },
    {
      title: "Letöltés",
      key: "download",
      render: (text: string, record: VideoT) => {
        return (
          <Space size="middle">
            <Button onClick={() => downloadContent(record)}>
              <DownloadOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: VideoT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: VideoT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a kontentet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteVideo(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const { total } = useSelector((state: RootStore) => state.videoReducer)
  const [current, setCurrent] = useState(1);
  const onChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setCurrent(pagination.current!);
    if (sorter.field === 'name') {
      const order = sorter.order === null ? `` : (sorter.order === `ascend` ? `ASC` : `DESC`);
      dispatch({ type: `VIDEO_CHANGE_SEARCH`, payload: { field: `titleOrder`, value: order } });
    }
    dispatch(VideoActions.getAllVideo(pagination.current!));
  }

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Videók</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={showModal}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
            <div className="deletedModal">
              <Button onClick={() => setShowDeletedModal(true)}>
                <RestOutlined />
              </Button>
            </div>
          </Row>
          <Space className="colPadding">
            Könyv alapú keresés:
            <Input
              name="booksearch"
              value={search.book}
              onChange={(e) => dispatch({ type: "VIDEO_CHANGE_SEARCH", payload: { field: `book`, value: e.target.value } }) }
            />
            {!onSearch ? (
              <Button type="primary" size="small" onClick={searchVideos}>
                <SearchOutlined />
              </Button>
            ) : (
              <Button type="primary" size="small" onClick={resetFilter}>
                <RetweetOutlined />
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={onSearch ? filteredVideos : propVideos}
        loading={!loaded && !propVideos}
        onChange={onChange}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          total,
          current,
          showSizeChanger: false,
        }}
      />
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        title={modify ? "Módosítás" : "Létrehozás"}
        maskClosable={false}
        forceRender
        centered
        footer={[
          <Button
            disabled={disabled}
            key="createPic"
            type="primary"
            onClick={modify ? functionModifyVideoName : createVideo}
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={closeModal}>
            Mégsem
          </Button>,
        ]}
        width={"55em"}
      >
        <Form
          form={form}
          {...layout}
          name="register"
          onFinish={modify ? functionModifyVideoName : createVideo}
          scrollToFirstError
        >
          {!modify && (
            <Form.Item
              name="file"
              label="Videofeltöltés"
              rules={[
                {
                  required: true,
                  message: "Kérem adjon meg egy fájlt!",
                },
              ]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleChangeImage}
                accept=".mp4,.mp4p,.mp4v"
              >
                {imageUrl ? (
                  <img src={pic} alt="avatar" className="coverPicPreview" />
                ) : (
                  UploadButton(loading)
                )}
              </Upload>
              <Button
                style={{
                  position: "absolute",
                  left: "9em",
                  top: "2.5em",
                }}
                size="small"
                danger
                type="primary"
                disabled={!file}
                onClick={() => removePictureFile(setFile, setImageUrl)}
              >
                <DeleteOutlined />
              </Button>
            </Form.Item>
          )}
          <Form.Item
            name="name"
            label="Név"
            rules={[
              {
                required: true,
                message: "Mező kitöltése kötelező!",
              },
            ]}
          >
            <Input
              style={modify ? { width: "37em" } : { width: "30em" }}
              name="name"
              value={name}
              maxLength={255}
              onChange={(e) => DebounceFunc(e.target.value, setName)}
            />
          </Form.Item>
          <Form.Item
            name="book"
            label="Könyv"
            rules={[
              {
                required: true,
                message: "Mező kitöltése kötelező!",
              },
            ]}
          >
            {modify ? (
              <Transfer
                style={{ textAlign: "left" }}
                dataSource={propBooks}
                render={(item) => `${item.title}`}
                targetKeys={booksTargetKeys}
                selectedKeys={booksSelectedKeys}
                showSearch
                onChange={(nextTargetKeys: string[]) =>
                  setBooksTargetKeys(nextTargetKeys)
                }
                onSelectChange={(
                  sourceSelectedKeys: string[],
                  targetSelectedKeys: string[]
                ) =>
                  setBooksSelectedKeys([
                    ...sourceSelectedKeys,
                    ...targetSelectedKeys,
                  ])
                }
                rowKey={(record) => record.id.toString()}
                selectAllLabels={[<div></div>, <div></div>]}
                filterOption={(inputValue, item) =>
                  item.title.toLowerCase()!.includes(inputValue.toLowerCase())
                }
                locale={{
                  notFoundContent: "Üres",
                  selectAll: "Összes kiválasztása",
                  selectInvert: "Kiválasztás törlése",
                }}
                listStyle={{
                  width: 240,
                  height: 300,
                }}
              />
            ) : (
              <Select
                style={{ width: "30em" }}
                value={bookId}
                onChange={(event) => setBookId(event)}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={propBooks.map((book: BookT) => ({
                  value: book.id,
                  label: book.title,
                }))}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={showDeletedModal}
        onCancel={closeDeletedVideosModal}
        maskClosable={true}
        forceRender
        footer={false}
        width={"87.5vw"}
        bodyStyle={{ height: "89.5vh" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: "0em",
          marginTop: "0em",
        }}
      >
        <DeletedVideos showDeletedModal={showDeletedModal} />
      </Modal>
    </div>
  );
}
