import { Button, Input, Popconfirm, Space, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store/store";
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import TaskActions from "../../actions/task";
import { TaskT } from "../../constants/ActionTypes/task-action-types";
import "../formating/content.css";
import useWindowDimensions from "../../constants/sizecheck";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";

export default function DeletedTasks({ showDeletedModal }: any) {
  const dispatch = useDispatch();
  const propDeletedTasks = useSelector(
    (state: RootStore) => state.taskReducer.deletedTasks
  );
  const propStatus = useSelector(
    (state: RootStore) => state.taskReducer.status
  );
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const { width } = useWindowDimensions();
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetch = async () => {
      if (showDeletedModal) {
        try {
          await dispatch(TaskActions.getAllDeletedTask());
          setLoaded(true);
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetch();
  }, [dispatch, showDeletedModal]);

  const getDeletedTasks = async () => {
    setLoaded(false);
    try {
      await dispatch(TaskActions.getAllDeletedTask());
      if (propStatus) {
        setLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const restoreDeletedTask = async (record: TaskT) => {
    await dispatch(TaskActions.restoreTask(record.id.toString()));
    await getDeletedTasks();
  };

  type DataIndex = keyof TaskT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<TaskT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Feladat megnevezés",
      key: "taskTitle",
      dataIndex: "title",
      ...getColumnSearchProps("title", "megnevezést"),
      sorter: (a: TaskT, b: TaskT) => {
        return a.title.localeCompare(b.title);
      },
      width: "35%",
    },
    {
      title: "Leírás",
      key: "taskDescription",
      dataIndex: "question",
      ...getColumnSearchProps("question", "leírást"),
      width: "35%",
    },
    {
      title: "Visszaállítás",
      key: "restore",
      render: (text: string, record: TaskT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan vissza akarod állítani ezt a feladatot?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => restoreDeletedTask(record)}
            >
              <Button type="primary">
                <RedoOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <h1 className="title">
        <strong>Törölt tesztsorok</strong>
      </h1>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propDeletedTasks}
        loading={!loaded && !propDeletedTasks}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
        scroll={{ y: width / 2 }}
      />
    </div>
  );
}
