import {
  DownloadOutlined,
  RedoOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoActions from "../../../actions/video";
import { VideoT } from "../../../constants/ActionTypes/video-action-types";
import { RootStore } from "../../../store/store";
import { downloadContent } from "./video";
import "../../formating/content.css";
import useWindowDimensions from "../../../constants/sizecheck";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";

export default function DeletedVideos({ showDeletedModal }: any) {
  const dispatch = useDispatch();
  const propVideos = useSelector(
    (state: RootStore) => state.videoReducer.deletedVideos
  );
  const propStatus = useSelector(
    (state: RootStore) => state.videoReducer.status
  );
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const { width } = useWindowDimensions();
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetch = async () => {
      if (showDeletedModal) {
        try {
          await dispatch(VideoActions.getAllDeletedVideo());
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetch();
    if (propStatus) {
      setLoaded(true);
    }
  }, [dispatch, showDeletedModal]);

  const getDeletedVideos = async () => {
    setLoaded(false);
    try {
      await dispatch(VideoActions.getAllDeletedVideo());
      if (propStatus) {
        setLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const restoreDeletedVideo = async (record: VideoT) => {
    await dispatch(VideoActions.restoreVideo(record.id.toString()));
    await getDeletedVideos();
  };

  type DataIndex = keyof VideoT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<VideoT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Videó neve",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "nevet"),
      sorter: (a: VideoT, b: VideoT) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Eredeti név",
      key: "originalName",
      dataIndex: "originalName",
      ...getColumnSearchProps("originalName", "eredeti nevet"),
    },
    {
      title: "Videó mérete",
      key: "fileSize",
      render: (text: string, record: VideoT) => {
        return `${record.fileSize} KB`;
      },
    },
    {
      title: "Könyv",
      key: "fileSize",
      render: (text: string, record: VideoT) => {
        return record.book?.map((b) => b.title + ", ");
      },
    },
    {
      title: "Létrehozva",
      key: "created",
      render: (text: string, record: VideoT) => {
        return new Date(record.created).toLocaleDateString();
      },
    },
    {
      title: "Letöltés",
      key: "download",
      render: (text: string, record: VideoT) => {
        return (
          <Space size="middle">
            <Button onClick={() => downloadContent(record)}>
              <DownloadOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Visszaállítás",
      key: "restore",
      render: (text: string, record: VideoT) => {
        return (
          <div>
            <Popconfirm
              title="Biztosan vissza akarod állítani ezt a videót?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => restoreDeletedVideo(record)}
            >
              <Button type="primary">
                <RedoOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <h1 className="title">
        <strong>Törölt videók</strong>
      </h1>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propVideos}
        loading={!loaded && !propVideos}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
        scroll={{ y: width / 2 }}
      />
    </div>
  );
}
