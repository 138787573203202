import axios from "axios";
import { BookDispatchTypes } from "../constants/ActionTypes/book-action-types";
import { Dispatch } from "redux";
import {store} from "../store/store";

const getAllBook = (withoutRelations?: boolean, page: number = 1): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    const { title, description, creators, titleOrder } = store.getState().bookReducer.search;
    dispatch({ type: "BOOKS_LOADING" });
    await axios
      .get(`/1.0.0/book/all?page=${ page }&title=${title || ""}&description=${description || ""}&creators=${creators || ""}&titleOrder=${titleOrder || ""}`, {
        params: { withoutRelations },
      })
      .then((response) => {
        dispatch({
          type: "BOOKS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOKS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllBooks = (): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "BOOKS_LOADING" });
    await axios
        .get(`/1.0.0/book/all?pagination=no&withoutRelations=true`, {})
        .then((response) => {
          dispatch({
            type: "ALL_BOOKS_LOADED",
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "BOOKS_ERROR",
            payload: err.response.data,
          });
        });
  };
};

const getAllDeletedBook = (): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "DELETED_BOOKS_LOADING" });
    await axios
      .get("/1.0.0/book/trash", {})
      .then((response) => {
        dispatch({
          type: "DELETED_BOOKS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETED_BOOKS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createBook = (params: FormData): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "BOOK_REGISTER_START" });
    await axios
      .post("/1.0.0/book/create", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "BOOK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyBook = (params: FormData): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "BOOK_REGISTER_START" });
    await axios
      .put("/1.0.0/book/modify", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "BOOK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const restoreBook = (id: string): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "BOOK_REGISTER_START" });
    await axios
      .put("/1.0.0/book/restore/" + id, {})
      .then((response) => {
        dispatch({
          type: "BOOK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const publicateBook = (bookId: number): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "BOOK_REGISTER_START" });
    await axios
      .put("/1.0.0/book/publicate/" + bookId, {})
      .then((response) => {
        dispatch({
          type: "BOOK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const prioritizationBook = (bookId: number): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "BOOK_REGISTER_START" });
    await axios
      .put("/1.0.0/book/prioritization/" + bookId, {})
      .then((response) => {
        dispatch({
          type: "BOOK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const duplicateBook = (bookId: number): any => {
  return async (dispatch: Dispatch<BookDispatchTypes>) => {
    dispatch({ type: "BOOK_REGISTER_START" });
    await axios
      .post("/1.0.0/book/duplicate/" + bookId, {})
      .then((response) => {
        dispatch({
          type: "BOOK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteBook = (bookId: number): any => {
  return async () => {
    await axios.delete("/1.0.0/book/" + bookId).catch((err) => {
      console.log(err.message);
    });
  };
};

const BookActions = {
  getAllBook,
  createBook,
  modifyBook,
  deleteBook,
  getAllDeletedBook,
  restoreBook,
  publicateBook,
  prioritizationBook,
  duplicateBook,
  getAllBooks,
};
export default BookActions;
