import axios from "axios";
import { Dispatch } from "redux";
import { WebContentDispatchTypes } from "../constants/ActionTypes/webcontent-action-types";
import {store} from "../store/store";

const getAllWebcontent = (page: number = 1): any => {
  return async (dispatch: Dispatch<WebContentDispatchTypes>) => {
    dispatch({ type: "WEBCONTENTS_LOADING" });
    const { name, originalName, book, titleOrder } = store.getState().webcontentReducer.search;
    await axios
      .get(`/1.0.0/webcontent?page=${ page }&name=${ name || ``}&originalName=${ originalName || ``}&book=${ book || `` }&titleOrder=${ titleOrder || `` }`, {})
      .then((response) => {
        dispatch({
          type: "WEBCONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "WEBCONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllDeletedWebContent = (): any => {
  return async (dispatch: Dispatch<WebContentDispatchTypes>) => {
    dispatch({ type: "DELETED_WEBCONTENTS_LOADING" });
    await axios
      .get("/1.0.0/webcontent/trash", {})
      .then((response) => {
        dispatch({
          type: "DELETED_WEBCONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETED_WEBCONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const uploadWebcontent = (params: FormData): any => {
  return async (dispatch: Dispatch<WebContentDispatchTypes>) => {
    dispatch({ type: "WEBCONTENT_REGISTER_START" });
    await axios
      .post("/1.0.0/webcontent/create", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "WEBCONTENT_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "WEBCONTENT_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyWebcontent = (params: FormData): any => {
  return async (dispatch: Dispatch<WebContentDispatchTypes>) => {
    dispatch({ type: "WEBCONTENT_REGISTER_START" });
    await axios
      .put("/1.0.0/webcontent/modify", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "WEBCONTENT_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "WEBCONTENT_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const restoreWebContent = (id: string): any => {
  return async (dispatch: Dispatch<WebContentDispatchTypes>) => {
    dispatch({ type: "WEBCONTENT_REGISTER_START" });
    await axios
      .put("/1.0.0/webcontent/restore/" + id, {})
      .then((response) => {
        dispatch({
          type: "WEBCONTENT_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "WEBCONTENT_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteWebcontent = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/webcontent/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const WebContentActions = {
  getAllWebcontent,
  uploadWebcontent,
  modifyWebcontent,
  deleteWebcontent,
  getAllDeletedWebContent,
  restoreWebContent,
};

export default WebContentActions;
