import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Transfer,
  Upload,
} from "antd";
import { BookT } from "../../../constants/ActionTypes/book-action-types";
import DebounceFunc from "../../../constants/debounce";
import { layout } from "../../../constants/layout";
import {
  handleChange,
  removePictureFile,
  UploadButton,
} from "../../../constants/pictureUpload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import PictureActions from "../../../actions/picture";
import { useEffect, useState } from "react";
import type { UploadChangeParam } from "antd/es/upload";
import { RootStore } from "../../../store/store";
import BookActions from "../../../actions/book";

const PictureModal = ({
  form,
  modalVisible,
  file,
  name,
  bookId,
  selectedId,
  booksTargetKeys,
  setFile,
  setImageUrl,
  getPictures,
  setModalVisible,
  setModify,
  setSelectedId,
  setName,
  setBookId,
  setBooksTargetKeys,
  modify,
  booksSelectedKeys,
  setBooksSelectedKeys,
  imageUrl,
  taskSequenceId,
  setTaskSequenceId,
}: any) => {
  const dispatch = useDispatch();
  const propBooks = useSelector((state: RootStore) => state.bookReducer.allBooks);
  const [checked, setChecked] = useState<boolean>(() => false);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [loading, setLoading] = useState<boolean>(() => false);

  useEffect(() => {
    dispatch(BookActions.getAllBooks());
  }, []);

  const saveStatus = useSelector(
    (state: RootStore) => state.pictureReducer.saveStatus
  );

  const onReset = () => {
    form.resetFields();
  };
  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    setSelectedId(null!);
    setName("");
    setBookId(null!);
    removePictureFile(setFile, setImageUrl);
    setBooksTargetKeys([]);
    onReset();
  };

  const functionCreatePicture = async () => {
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
      formData.append("name", name);
      formData.append("bookId", bookId.toString());
      formData.append("taskSequenceNumber", taskSequenceId);
    }
    await dispatch(PictureActions.createPicture(formData));
    setChecked(true);
  };

  const beforeUpload = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setFile(null!);
      setImageUrl("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setFile(null!);
            setImageUrl("");
          }
        };
      };
    }
    return false;
  };

  const modifyPicture = async () => {
    const modify = {
      id: selectedId,
      name: name,
      bookIds: booksTargetKeys,
      taskSequenceNumber: taskSequenceId,
    };
    await dispatch(PictureActions.modifyPictureName(modify));
    setChecked(true);
  };

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(info, setLoading, setFile, setImageUrl);
  };

  useEffect(() => {
    if (saveStatus && checked) {
      message.success("Sikeres mentés");
    } else if (!saveStatus && checked) {
      message.error("Hiba a kép mentése közben!");
    }
    return () => {
      if (saveStatus && checked) {
        getPictures();
        closeModal();
      }
      setChecked(false);
    };
  });

  useEffect(() => {
    if (!modify ? !file || !name || !bookId : !name || !booksTargetKeys) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [file, name, bookId]);

  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      title={modify ? "Módosítás" : "Létrehozás"}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="createPic"
          type="primary"
          onClick={modify ? modifyPicture : functionCreatePicture}
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width={"55em"}
    >
      <Row>
        <Col span={24} className="colPadding">
          <Form
            form={form}
            {...layout}
            name="register"
            onFinish={modify ? modifyPicture : functionCreatePicture}
            scrollToFirstError
          >
            {!modify && (
              <Form.Item
                name="file"
                label="Képfeltöltés"
                rules={[
                  {
                    required: true,
                    message: "Kérem adjon meg egy fájlt!",
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUpload}
                  onChange={handleChangeImage}
                  accept=".png,.jpg,.jpeg,.gif"
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      className="coverPicPreview"
                    />
                  ) : (
                    UploadButton(loading)
                  )}
                </Upload>
                <Button
                  style={{
                    position: "absolute",
                    left: "9em",
                    top: "2.5em",
                  }}
                  size="small"
                  danger
                  type="primary"
                  disabled={!file}
                  onClick={() => removePictureFile(setFile, setImageUrl)}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            )}
            <Form.Item
              name="name"
              label="Név"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Input
                style={{ maxWidth: "37.5em" }}
                name="name"
                value={name}
                maxLength={255}
                onChange={(e) => DebounceFunc(e.target.value, setName)}
              />
            </Form.Item>

            <Form.Item
              name="book"
              label="Könyv"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              {modify ? (
                <Transfer
                  style={{ textAlign: "left" }}
                  dataSource={propBooks}
                  render={(item) => `${item.title}`}
                  targetKeys={booksTargetKeys}
                  selectedKeys={booksSelectedKeys}
                  showSearch
                  onChange={(nextTargetKeys: string[]) =>
                    setBooksTargetKeys(nextTargetKeys)
                  }
                  onSelectChange={(
                    sourceSelectedKeys: string[],
                    targetSelectedKeys: string[]
                  ) =>
                    setBooksSelectedKeys([
                      ...sourceSelectedKeys,
                      ...targetSelectedKeys,
                    ])
                  }
                  rowKey={(record) => record.id.toString()}
                  selectAllLabels={[<div></div>, <div></div>]}
                  filterOption={(inputValue, item) =>
                    item
                      ?.title!.toLowerCase()!
                      .includes(inputValue.toLowerCase())
                  }
                  locale={{
                    notFoundContent: "Üres",
                    selectAll: "Összes kiválasztása",
                    selectInvert: "Kiválasztás törlése",
                  }}
                  listStyle={{
                    width: 240,
                    height: 300,
                  }}
                />
              ) : (
                <Select
                  style={{ width: "37.5em" }}
                  value={bookId}
                  onChange={(event) => setBookId(event)}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={propBooks.map((book: BookT) => ({
                    value: book.id,
                    label: book.title,
                  }))}
                />
              )}
            </Form.Item>
            <Form.Item name="taskSequenceId" label="Feladat sorszáma">
              <Input
                style={{ maxWidth: "10em" }}
                value={taskSequenceId}
                name="taskSequenceId"
                maxLength={6}
                onChange={(e) =>
                  DebounceFunc(e.target.value, setTaskSequenceId)
                }
                min={1}
              />
            </Form.Item>
            {modify && (
              <div>
                <Form.Item name="originalName" label="Eredeti név">
                  <Input
                    style={{ width: "37.5em" }}
                    disabled={true}
                    name="originalName"
                    maxLength={255}
                  />
                </Form.Item>

                {/* NINCS ELDÖNTVE HOGY KELL E ÍGY NEKIK
                   <Form.Item name="fileSize" label="File mérete">
                    <Input
                      style={{ width: "37.5em" }}
                      disabled={true}
                      name="fileSize"
                      maxLength={255}
                    />
                  </Form.Item> */}
                <Form.Item name="created" label="Létrehozva">
                  <Input
                    style={{ width: "37.5em" }}
                    disabled={true}
                    name="created"
                    maxLength={255}
                  />
                </Form.Item>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default PictureModal;
