import axios from "axios";
import { Dispatch } from "redux";
import { TaskDispatchTypes } from "../constants/ActionTypes/task-action-types";
import {store} from "../store/store";

export const getAllTask = (page: number = 1): any => {
  return async (dispatch: Dispatch<TaskDispatchTypes>) => {
    const { title, question, book, titleOrder } = store.getState().taskReducer.search;
    dispatch({ type: "TASKS_LOADING" });
    await axios
      .get(`/1.0.0/task/all?page=${page}&title=${title || ``}&question=${question || ``}&book=${book || ``}&titleOrder=${titleOrder || ``}`, {})
      .then((response) => {
        dispatch({
          type: "TASKS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "TASKS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllDeletedTask = (): any => {
  return async (dispatch: Dispatch<TaskDispatchTypes>) => {
    dispatch({ type: "DELETED_TASKS_LOADING" });
    await axios
      .get("/1.0.0/task/trash", {})
      .then((response) => {
        dispatch({
          type: "DELETED_TASKS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETED_TASKS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createTask = (params: object): any => {
  return async (dispatch: Dispatch<TaskDispatchTypes>) => {
    dispatch({ type: "TASK_REGISTER_START" });
    await axios
      .post("/1.0.0/task/create", params, {})
      .then((response) => {
        dispatch({
          type: "TASK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "TASK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const duplicateTask = (id: number): any => {
  return async (dispatch: Dispatch<TaskDispatchTypes>) => {
    dispatch({ type: "TASK_REGISTER_START" });
    await axios
      .post(`/1.0.0/task/duplicate/${id}`, {})
      .then((response) => {
        dispatch({
          type: "TASK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "TASK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyTask = (params: object): any => {
  return async (dispatch: Dispatch<TaskDispatchTypes>) => {
    dispatch({ type: "TASK_REGISTER_START" });
    await axios
      .put("/1.0.0/task/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "TASK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "TASK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const restoreTask = (id: string): any => {
  return async (dispatch: Dispatch<TaskDispatchTypes>) => {
    dispatch({ type: "TASK_REGISTER_START" });
    await axios
      .put("/1.0.0/task/restore/" + id, {})
      .then((response) => {
        dispatch({
          type: "TASK_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "TASK_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteTask = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/task/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const TaskActions = {
  getAllTask,
  createTask,
  modifyTask,
  deleteTask,
  getAllDeletedTask,
  restoreTask,
  duplicateTask,
};

export default TaskActions;
