import {
  WebContentDispatchTypes,
  WebContentT,
} from "../constants/ActionTypes/webcontent-action-types";

export interface initialWebContentState {
  webcontent: WebContentT[];
  deletedWebcontent: WebContentT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  total: number;
  search: any;
}

const defaultState = {
  webcontent: [],
  deletedWebcontent: [],
  status: false,
  message: "",
  saveStatus: false,
  total: 0,
  search: {
    name: "",
    originalName: "",
    book: "",
    titleOrder: "",
  }
};
const webcontentReducer = (
  state: initialWebContentState = defaultState,
  action: WebContentDispatchTypes
): initialWebContentState => {
  switch (action.type) {
    case "WEBCONTENTS_LOADING":
      return Object.assign({}, state, {
        webcontent: [],
        status: false,
        message: "",
      });
    case "WEBCONTENTS_LOADED":
      return Object.assign({}, state, {
        webcontent: action.payload.webContents,
        total: action.payload.count,
        status: true,
        message: "",
      });
    case "WEBCONTENTS_ERROR":
      return Object.assign({}, state, {
        webcontent: [],
        status: false,
        message: action.payload,
      });
    case "WEBCONTENT_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "WEBCONTENT_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "WEBCONTENT_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case "DELETED_WEBCONTENTS_LOADING":
      return Object.assign({}, state, {
        deletedWebcontent: [],
        status: false,
        message: "",
      });
    case "DELETED_WEBCONTENTS_LOADED":
      return Object.assign({}, state, {
        deletedWebcontent: action.payload.webContents,
        status: true,
        message: "",
      });
    case "DELETED_WEBCONTENTS_ERROR":
      return Object.assign({}, state, {
        deletedWebcontent: [],
        status: false,
        message: action.payload,
      });
    case "WEBCONTENT_CHANGE_SEARCH":
      const s = state.search;
      (s as any)[action.payload.field] = action.payload.value;
      const n = {
        search: s
      };
      return Object.assign({}, state, n);
    default:
      return state;
  }
};

export default webcontentReducer;
