import { Button, Col, Form, message, Row } from "antd";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserActions from "../../actions/users";
import { UserT } from "../../constants/ActionTypes/login-action-types";
import { RootStore } from "../../store/store";
import "../formating/content.css";
import AdminModal from "./modal";
import AdminTable from "./table";
import reducer, { initialState } from "./reducer";
import {
  useCheckedChange,
  useEmailChange,
  useIsDeleteChange,
  useLoadedChange,
  useNameChange,
  usePasswordChange,
} from "./adminUtils";

export default function AdminUsers() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAdmins = useSelector(
    (state: RootStore) => state.usersReducer.admins
  );
  const status = useSelector((state: RootStore) => state.usersReducer.status);
  const saveStatus = useSelector(
    (state: RootStore) => state.usersReducer.saveStatus
  );
  const functionRan = useRef(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const { email, password, name, loaded, checked, isDelete } = state;
  const emailChange = useEmailChange(reducerDispatch);
  const passwordChange = usePasswordChange(reducerDispatch);
  const nameChange = useNameChange(reducerDispatch);
  const loadedChange = useLoadedChange(reducerDispatch);
  const checkedChange = useCheckedChange(reducerDispatch);
  const isDeleteChange = useIsDeleteChange(reducerDispatch);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      if (
        functionRan.current === false ||
        (checked && saveStatus) ||
        isDelete
      ) {
        dispatch(UserActions.getAdmins(current));
        if (status) {
          loadedChange(true);
          checkedChange(false);
          isDeleteChange(false);
        }
      }
    };
    fetch();
    return () => {
      functionRan.current = true;
    };
  }, [
    dispatch,
    checkedChange,
    isDeleteChange,
    loadedChange,
    status,
    checked,
    isDelete,
    saveStatus,
  ]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    nameChange("");
    emailChange("");
    passwordChange("");
    form.resetFields();
  }, [form, nameChange, emailChange, passwordChange]);

  useEffect(() => {
    if (saveStatus && checked) {
      checkedChange(false);
      closeModal();
      message.success("Sikeres mentés");
    } else if (!saveStatus && checked) {
      checkedChange(false);
      message.error("Hiba a mentés közben", 5);
    }
  }, [checked, checkedChange, closeModal, saveStatus]);

  const showModal = () => {
    setModalVisible(true);
  };

  const createAdmin = async () => {
    const toSend = {
      name: name,
      email: email,
      password: password,
    };
    await dispatch(UserActions.registerAdmin(toSend));
    setCurrent(1);
    checkedChange(true);
  };

  const functionDeleteAdmin = async (record: UserT) => {
    await dispatch(UserActions.deleteAdmin(record.id));
    isDeleteChange(true);
  };

  const validateMessage = "Nem egyezik meg a két jelszó!";
  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Adminok</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={showModal}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <AdminTable
        propAdmins={propAdmins}
        loaded={loaded}
        functionDeleteAdmin={functionDeleteAdmin}
        current={current}
        setCurrent={setCurrent}
      />
      <AdminModal
        modalVisible={modalVisible}
        closeModal={closeModal}
        createAdmin={createAdmin}
        form={form}
        name={name}
        nameChange={nameChange}
        email={email}
        emailChange={emailChange}
        password={password}
        passwordChange={passwordChange}
        validateMessage={validateMessage}
      />
    </div>
  );
}
